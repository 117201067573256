import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import {
  getSchoolData,
  getSessionData,
  getUserData
} from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";
import AttendanceRegisterList from "./AttendanceRegisterList";

const { Option } = Select;

const AttendanceRegister = () => {
  const [state, setState] = useState({
    classSection: null,
    month_id: null,
    month_name: null,
  });
  const [classList, setClassList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [showList, setShowList] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getClassList();
    console.log(getSchoolData());
    if (getSchoolData().attendanceType === 1) {
      getMonthList();
    }
  }, []);

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    setShowList(false);
  };

  const handleMonthChange = (field, value) => {
    let monthName = "NA";
    monthList.map((m) => (
      m.id === value ? (monthName = m.month) : "NA"
    ));

    setState({ ...state, [field]: value, month_name: monthName });
    setShowList(false);
  };

  const getClassList = async () => {
    setSpinner(true);
    const classRes = await postRequest("get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    });

    setClassList(classRes.data.response.as_class_teacher);
    setSpinner(false);
  };

  const getMonthList = async () => {
    setSpinner(true);
    const monthRes = await postRequest("get-month-by-session", {
      sessionCode: getSessionData().rcode
    });
    setMonthList(monthRes.data.response.data);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Attendance Register"
        pageIcon={<i className='subheader-icon fal fa-hand-point-up'></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Attendance Register</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="class_section"
                        label="Class"
                        rules={[
                          {
                            required: true,
                            message: "Please select class!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Class"
                          onChange={(value) =>
                            handleSelectChange("classSection", value)
                          }
                        >
                          {!!classList &&
                            classList.map((s) => (
                              <Option key={s} value={s}>
                                {s}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {getSchoolData().attendanceType === 1 && (
                      <Col xs={24} sm={8} lg={8}>
                        <Form.Item
                          name="month_id"
                          label="Select Month"
                          rules={[
                            {
                              required: true,
                              message: "Please select month!",
                            },
                          ]}
                        >
                          <Select placeholder="Select Month"
                            onChange={(value) =>
                              handleMonthChange("month_id", value)
                            }
                          >
                            {!!monthList &&
                              monthList.map((m, k) => (
                                <Option key={k} value={m.id}>
                                  {m.month}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        setShowList(true)
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Student List
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showList && (
        <AttendanceRegisterList
          classSection={state.classSection}
          monthId={state.month_id}
          monthName={state.month_name}
          setShowList={(value) => {
            setShowList(value)
          }}
        />
      )}
    </div>
  );
};

export default AttendanceRegister;