import React, { useState, useRef } from "react";
import { Modal, Form, Button, Col, Row, Select, Radio } from "antd";

import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const FeePayment = (props) => {
  const formRef = useRef();
  const [state, setState] = useState({
    paymentGateway: null,
    selectedMonth: [],
    platform: "web",
    schoolCode: null
  });
  const [showModel, setShowModel] = useState(false);
  const [showPGModel, setShowPGModel] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [pgData, setPGData] = useState(null);
  // const [totalAmount, setTotalAmount] = useState(props.amountPerMonth + Number(props.totalPrevDue));
  const [totalAmount, setTotalAmount] = useState(props.totalDueAmount);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
  };

  const hidePGModelFunction = () => {
    setShowPGModel(false);
  };

  const showPGModelFunction = () => {
    setShowPGModel(true);
  };

  const handleMonthChange = (value) => {
    setState({ ...state, selectedMonth: value });

    let totAmt = 0;
    value.map((v) => {
      let val = v.split("-");
      totAmt = Number(totAmt) + Number(val[2]);
    })
    totAmt = totAmt + Number(props.totalPrevDue);
    setTotalAmount(totAmt);
  };

  const handlePGChange = (e) => {
    setState({ ...state, paymentGateway: e.target.value });
  };

  const onFinish = async () => {
    setSpinner(true);

    let selectedMonthArr = [];
    state.selectedMonth.map((month) => {
      let sInfo = month.split("-");
      selectedMonthArr.push({ monthId: sInfo[0], monthName: sInfo[1] });
    });

    const payload = {
      form_data: {
        paymentGateway: state.paymentGateway,
        schoolCode: getSchoolData().school_code,
        sessionCode: getSessionData().rcode,
        sid: getUserData().referenceId,
        categoryId: props?.categoryId,
        nameArray: props?.nameArray,
        amount: totalAmount,
        selectedMonth: selectedMonthArr,
        platform: "web",
      },
    };

    setBtnLoading(true);

    try {
      let res = await postRequest("get-payment-gateway-detail", payload);

      setSpinner(false);
      if (res.data.error === 0) {
        setBtnLoading(false);
        setPGData(res.data.response.data);
        hideModelFunction();
        showPGModelFunction();
      } else {
        setBtnLoading(false);
        ErrorNotificationMsg("Error in fees payment.");
      }
    } catch (error) {
      setBtnLoading(false);
      setSpinner(false);
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <button
        onClick={() => showModelFunction()}
        className="btn btn-block btn-success"
        type="submit"
      >
        PAY NOW
      </button>

      <Modal
        title="Select Month for Payment"
        open={showModel}
        onCancel={hideModelFunction}
        footer={false}
      >
        <Form
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
        >
          {props.feesType === 2 && (
            <Row gutter={[15]}>
              <Col xs={24} sm={24} lg={24}>
                <Form.Item
                  name="months"
                  label="Select Month(s)"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one month!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Month"
                    onChange={(value) => handleMonthChange(value)}
                  >
                    {!!props.feesDueDetail &&
                      props.feesDueDetail.map((s) => (
                        <Option
                          key={s.monthId}
                          value={s.monthId + "-" + s.monthName + "-" + s.monthAmount}
                        >
                          {s.monthName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={24}>
              {props.feesByMonth ?
                <span className="d-block mb-3 mt-2">
                  TOTAL AMOUNT : <i className="fal fa-rupee-sign"></i> <strong>{totalAmount}</strong>
                </span>
                :
                <table className="table table-sm table-bordered table-hover">
                  <thead className="thead-themed text-center">
                    <tr>
                      <th>
                        PREVIOUS DUE
                        <br />
                        (1)
                      </th>
                      <th>
                        NUMBER OF MONTH(S)
                        <br />
                        (2)
                      </th>
                      <th>
                        AMOUNT
                        <br />
                        (3)
                      </th>
                      <th>
                        TOTAL
                        <br />
                        (1+2x3)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-right">
                        <i className="fal fa-rupee-sign"></i>
                        <span>{" "}{props?.totalPrevDue}</span>
                      </td>
                      <td className="text-right">
                        <span>{state.selectedMonth.length}</span>
                      </td>
                      <td className="text-right">
                        <i className="fal fa-rupee-sign"></i>
                        <span>{" "}{props?.amountPerMonth}</span>
                      </td>
                      <td className="text-right">
                        <i className="fal fa-rupee-sign"></i>
                        <span>{" "}{totalAmount}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </Col>
          </Row>

          <Row gutter={[15]} className="mb-3">
            <Col xs={24} sm={24} lg={24}>
              <Form.Item
                name="paymentGateway"
                label="Payment Gateway"
                rules={[
                  {
                    required: true,
                    message: "Please choose Payment Gateway!",
                  },
                ]}
              >
                <Radio.Group onChange={handlePGChange}>
                  {!!getSchoolData().paymentGatewayList &&
                    getSchoolData().paymentGatewayList.map((pg) => (
                      <Radio key={pg.pg_name} value={pg.pg_name}>{pg.pg_name}</Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <div className="panel-content mt-2 d-flex flex-row">
            <Button
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              className="btn btn-success ml-auto waves-effect waves-themed"
            >
              PAY NOW
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Fees Payment"
        open={showPGModel}
        onCancel={hidePGModelFunction}
        footer={false}
      >
        {state.paymentGateway === 'BillDesk' ?
          <>
            {getSchoolData().school_code == '1911023' ?
              <>
                <p>
                  Payable Amount : {totalAmount}
                </p>
                <form name="submitFrm" action={encodeURI(pgData?.callUrl)} method="POST">
                  <input
                    type="submit"
                    value={"Redirect to " + state.paymentGateway}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  />
                </form>
              </>
              :
              <>
                <p>
                  Payable Amount : {totalAmount}
                </p>
                <form action="https://pgi.billdesk.com/pgidsk/PGIMerchantPayment" method="POST">
                  <input
                    type="hidden"
                    name="msg"
                    value={pgData?.pgDetails?.msg}
                  />
                  <input
                    type="submit"
                    value={"Redirect to " + state.paymentGateway}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  />
                </form>
              </>
            }
          </>
          :
          <>
            {state.paymentGateway === 'eazypay' ?
              <>
                <p>
                  Payable Amount : {totalAmount}
                </p>
                <form name="submitFrm" action={encodeURI(pgData?.pgDetails?.paymentUrl)} method="POST">
                  <input
                    type="submit"
                    value={"Redirect to " + state.paymentGateway}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  />
                </form>
              </>
              :
              <>
                <p>
                  Payable Amount : {totalAmount}
                </p>
                <form action="https://secure.payu.in/_payment" method="POST">
                  <input
                    type="hidden"
                    name="key"
                    value={pgData?.pgDetails?.merchantKey}
                  />
                  <input type="hidden" name="hash" value={pgData?.pgDetails?.hash} />
                  <input type="hidden" name="txnid" value={pgData?.pgDetails?.txnid} />
                  <input
                    type="hidden"
                    name="amount"
                    value={pgData?.pgDetails?.amount}
                  />
                  <input
                    type="hidden"
                    name="firstname"
                    value={pgData?.pgDetails?.firstname}
                  />
                  <input type="hidden" name="email" value={pgData?.pgDetails?.email} />
                  <input type="hidden" name="phone" value={pgData?.pgDetails?.phone} />
                  <input
                    type="hidden"
                    name="productinfo"
                    value={pgData?.pgDetails?.productinfo}
                  />
                  {/* <input
                type="hidden"
                name="service_provider"
                value="payu_paisa"
                size="64"
              /> */}
                  <input type="hidden" name="surl" value={pgData?.pgDetails?.surl} />
                  <input type="hidden" name="furl" value={pgData?.pgDetails?.furl} />
                  <input type="hidden" name="udf1" value={pgData?.pgDetails?.udf1} />
                  <input type="hidden" name="udf2" value={pgData?.pgDetails?.udf2} />
                  <input type="hidden" name="udf3" value={pgData?.pgDetails?.udf3} />
                  <input type="hidden" name="udf4" value={pgData?.pgDetails?.udf4} />
                  <input type="hidden" name="udf5" value={pgData?.pgDetails?.udf5} />
                  <input
                    type="submit"
                    value={"Redirect to " + state.paymentGateway}
                    className="btn btn-primary ml-auto waves-effect waves-themed"
                  />
                </form>
              </>
            }
          </>
        }
      </Modal>
    </>
  );
};

export default FeePayment;
