import React, { useEffect, useState } from "react";
import { Modal, Space } from "antd";

import { postRequest } from "../../axios";
import { getSessionData } from "../../utils/Helpers";
import {
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";
import userIcon from "../../images/userIcon.jpg";

const AttendanceInformationList = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [studentList, setStudentList] = useState(null);
  const setSpinner = useOutletContext();

  useEffect(() => {
    showModelFunction();
  }, []);


  const hideModelFunction = () => {
    setShowModel(false);
    props.setShowList(false);
  };

  const showModelFunction = () => {
    getAttendentList();
    setShowModel(true);
  };

  const getAttendentList = async () => {
    if (props.classSection !== null && props.monthId !== null) {
      setSpinner(true);
      let stdClass = props.classSection.slice(0, -2);
      let stdSection = props.classSection.slice(-1);

      try {
        const response = await postRequest("get-student-attendance-information-total", {
          sid: getSessionData().code,
          month: props.monthId,
          sclass: stdClass,
          sections: stdSection,
        });

        if (response.data.error === 0) {
          setStudentList(response.data.response);
        } else {
          ErrorNotificationMsg(response.data.errmsg);
          hideModelFunction();
        }
        setSpinner(false);
      } catch (error) {
        ErrorNotificationMsg("No records found.");
        setSpinner(false);
      }
    } else {
      ErrorNotificationMsg("Please select all the fields");
      hideModelFunction();
    }
  };

  return (
    <>
      <Modal
        title={"Student List of Class " + props.classSection}
        open={showModel}
        onCancel={hideModelFunction}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={false}
      >

        <div className="row">
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-hover">
              <thead className="thead-themed">
                <tr className="text-center">
                  <th>ROLL</th>
                  <th>PHOTO</th>
                  <th>NAME</th>
                  <th>MONTH</th>
                  <th>TOTAL</th>
                  <th>PERCENTAGE</th>
                </tr>
              </thead>
              <tbody>
                {studentList &&
                  studentList.map((student) => {
                    return (
                      <tr key={student.s_id}>
                        <td className="text-right">{student.roll}</td>
                        <td className="text-center">
                          <img
                            src={student?.profile_image}
                            alt="created-by-img"
                            className="profile-image rounded-circle"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = userIcon;
                            }}
                          />
                        </td>
                        <td>{student?.name}</td>
                        <td className="text-right">{student?.persent_month}</td>
                        <td className="text-right">{student?.persent_year}</td>
                        <td className="text-right">{student?.percent}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="panel-content d-flex flex-row justify-content-end">
            <Space>
              <button
                onClick={hideModelFunction}
                className="btn btn-default ml-auto waves-effect waves-themed"
              >
                Close
              </button>
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AttendanceInformationList;